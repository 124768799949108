<script setup lang="ts">
import { EwPopover } from '@arkit/ew-vue-components'
import EventPopover from './EventPopover.vue'
import type { EventContentArg } from '@fullcalendar/core'
import type { EventItem } from '@/types'
const props = defineProps({
  instance: { type: Object as PropType<EventContentArg>, required: true }
})
const event = computed(() => props.instance.event)
const eventData = computed(() => event.value.extendedProps as EventItem)
const viewType = computed(() => props.instance.view.type)
</script>
<template>
  <EwPopover trigger="click" placement="top" persist="content" class="h-full cursor-pointer">
    <div
      class="p-2 flex flex-col justify-between cursor-pointer"
      :class="[viewType === 'dayGridMonth' ? 'gap-1' : 'gap-2']"
    >
      <div v-if="viewType === 'dayGridMonth'" class="flex items-center gap-1">
        <EwIcon icon="tabler:clock" />{{ eventData.starttime }} <span class="opacity-80">({{eventData.nrofevents}}h)</span>
      </div>
      <div class="truncate font-medium">{{ event.title }}</div>
      <div class="flex justify-between gap-3">
        <span v-if="eventData.classroom" class="flex items-center gap-1 whitespace-nowrap">
          <EwIcon icon="tabler:building" />
          <span v-for="classroom in eventData.classroom" :key="classroom.id">
            {{ classroom.short }}
          </span>
        </span>
        <span v-if="eventData.teacherids" class="flex items-center gap-1 truncate">
          <EwIcon icon="tabler:user" />
          <span v-for="teacher in eventData.teacherids" :key="teacher.id">{{
            `${teacher.firstname} ${teacher.lastname}`
          }}</span>
        </span>
      </div>
    </div>
    <template #content="{ close }">
      <EventPopover :instance="props.instance" :close="close" />
    </template>
  </EwPopover>
</template>
