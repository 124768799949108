import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import { DefaultConfig } from './config'
import { merge } from 'lodash'
import type {
  CallResponse,
  ResponseData,
  EventItem,
  FiltersDefinition,
  FiltersResponseDefinition,
  FilterItemDefinition,
  FilterResponseItemDefinition,
  ProgramFilterOption,
  CardInfo,
  PotentialEventTypes,
  SubjectResponseFilter,
  SubjectFilterOption,
  CalendarDates,
  AdditionalCalendar
} from './types'
import { stringToColour } from '@e-university/utils'
import { requestWindowAction } from '@e-university/utils'

export const useDataStore = defineStore('calendar-data', () => {
  // * Get config from global properties of app instance
  const vueAppInstance = getCurrentInstance()
  const initialazationConfig =
    vueAppInstance?.appContext.config.globalProperties.$timetableCalendar ?? {}
  const ModuleConfig = merge(DefaultConfig, initialazationConfig)
  // * Start of data store
  const responseData = ref<EventItem[]>(parseEvents(ModuleConfig.data.list))
  const eventsList = computed(() => responseData.value)

  const _otherCalendars = ref<Record<AdditionalCalendar['id'], AdditionalCalendar>>(
    ModuleConfig.data.otherCalendars
  )
  const otherCalendars = computed(() => _otherCalendars.value)

  const calendarView = ref(ModuleConfig.config.defaultview)

  const filters = ref<FiltersDefinition>(parseFilters(ModuleConfig.data.filters))
  const selectedFilters = computed(() => {
    const result: Partial<Record<keyof FiltersDefinition, Array<string | number | undefined>>> = {}
    Object.keys(filters.value).forEach((key) => {
      const filterKey = key as keyof FiltersDefinition
      result[filterKey] = filters.value[filterKey]?.selectedObject?.map((item) => {
        //console.log(item)
        if (item) return item.id
      })
    })
    return result
  })

  const _calendarDates = ref<CalendarDates>({ from: null, to: null })
  const calendarDates = computed({
    get: () => _calendarDates.value,
    set: (value) => {
      _calendarDates.value = value
    }
  })

  function findFilterObject(filter: FilterResponseItemDefinition) {
    let filterFound = undefined
    for (const item of filter.values) {
      const itemId = item.id as string | number
      const filterSelected = filter.selected as (string | number)[]
      if (filterSelected.includes(itemId)) {
        filterFound = item
        break
      }
      const withchilds = item as ProgramFilterOption
      if (withchilds.childs) {
        for (const child of withchilds.childs) {
          const childId = child.id as string | number
          if (filterSelected.includes(childId)) {
            filterFound = child
            break
          }
        }
      }
    }
    return filterFound
  }

  function prepareFilters(filter: FilterResponseItemDefinition): FilterItemDefinition {
    const found = findFilterObject(filter)
    return {
      ...filter,
      //@ts-ignore-next-line
      selectedObject: found ? [found] : []
    }
  }
  function setSubjectColors(subjects: SubjectResponseFilter) {
    return subjects.values.map((item) => ({
      ...item,
      color: stringToColour(item.name)
    })) as Array<SubjectFilterOption & { color: string }>
  }
  function parseFilters(filters: FiltersResponseDefinition) {
    const result: FiltersDefinition = {}
    Object.keys(filters).forEach((key) => {
      const filterKey = key as keyof FiltersResponseDefinition
      const filter = filters[filterKey]
      if (filter && key !== 'calendardate') {
        //@ts-ignore-next-line
        result[filterKey] = prepareFilters(filter)
      }
    })
    if (result.subjects) {
      result.subjects.values = setSubjectColors(result.subjects)
    }
    return result
  }

  function parseFiltersAfterRequest(newFilters: FiltersResponseDefinition) {
    Object.keys(newFilters).forEach((key) => {
      const filterKey = key as keyof FiltersResponseDefinition
      const newFilter = newFilters[filterKey]
      const currentFilter = filters.value[filterKey]
      if (currentFilter && newFilter && key !== 'calendardate') {
        currentFilter.values = newFilter.values
        if (filterKey === 'subjects') {
          currentFilter.values = setSubjectColors(currentFilter as SubjectResponseFilter)
        }
      }
    })
  }

  function parseEvents(events: EventItem[]) {
    const result: EventItem[] = []
    for (const event of events) {
      const parsedEvent = {
        ...event,
        //date: new Date(event.ISOdate),
        start: new Date(event.ISOdate),
        end: new Date(event.startdate),
        startTime: event.starttime,
        endTime: event.endtime,
        startRecur: new Date(event.ISOdate),
        endRecur: new Date(event.startdate),
        title: event.subject.name,
        color: stringToColour(event.subject.name)
      }
      result.push(parsedEvent)
    }
    return result
  }

  const hasFiltersDefined = computed(() => {
    return Object.keys(filters.value).length > 0
  })
  // * Flag to check whether at least one filter has beeen set
  const hasFiltersSet = computed(() => {
    const hasAnyFilterSet =
      Object.values(selectedFilters.value).some(
        (item) => item?.filter((subitem) => !!subitem).length > 0
      ) || !ModuleConfig.config.loadOnlyFiltered
    const hasScopeSet =
      ModuleConfig.config.userScope !== 'none' || ModuleConfig.config.stateScope !== 'none'
    return hasAnyFilterSet || hasScopeSet
  })

  const hasDateSet = computed(() => {
    return calendarDates.value.from !== null && calendarDates.value.to !== null
  })

  //****************************************************/
  // * Watcher to react on filter changes
  watch(selectedFilters, () => {
    if (hasFiltersSet.value && hasDateSet.value) {
      getData()
    } else {
      responseData.value = []
    }
  })

  watch(
    calendarDates,
    () => {
      //console.log('calendarDates', calendarDates.value, hasFiltersSet.value, hasDateSet.value)
      if (hasFiltersSet.value && hasDateSet.value) {
        getData()
      } else {
        responseData.value = []
      }
    },
    { deep: true }
  )

  // * Watcher to clear filters when program filter is set
  watch(
    () => filters.value.program?.selectedObject,
    () => {
      if (filters.value.program?.selectedObject[0]) {
        if (filters.value.classes) filters.value.classes.selectedObject = []
        if (filters.value.classrooms) filters.value.classrooms.selectedObject = []
        if (filters.value.subjects) filters.value.subjects.selectedObject = []
        if (filters.value.teachers) filters.value.teachers.selectedObject = []
      }
    }
  )
  //****************************************************/

  const dataLoading = ref(false)
  const dataLoaded = ref(false)
  const dataError = ref(false)

  const getData = async () => {
    // * Check if getData url is defined otherwise throw error
    if (!ModuleConfig.urls.getData) {
      console.error('window.TimetableCalendarConfig.urls.getData is not defined')
      return
    }
    // * Check if filters are set and date is set otherwise stop call
    if (!hasFiltersSet.value || !hasDateSet.value) return

    dataLoading.value = true
    try {
      //console.log(selectedFilters.value)
      const { data: response } = await axios.get<CallResponse<ResponseData>>(
        ModuleConfig.urls.getData,
        { params: { ...selectedFilters.value, ...calendarDates.value } }
      )
      if (response.data.list) {
        responseData.value = parseEvents(response.data.list)
      } else {
        responseData.value = []
      }
      if (response.data.filters) {
        parseFiltersAfterRequest(response.data.filters)
        await nextTick()
      }
      dataLoaded.value = true
    } catch (e) {
      dataError.value = true
    } finally {
      dataLoading.value = false
    }
  }

  getData()

  const moduleConfig = computed(() => ModuleConfig.config)
  const moduleMessages = computed(() => ModuleConfig.messages)

  function recordEvent(args: {
    cardid: CardInfo['cardid']
    carddate: CardInfo['carddate']
    eventType: keyof PotentialEventTypes
  }) {
    const { cardid, carddate, eventType } = args
    console.log('handleAddAction', cardid, carddate, eventType)
    if (ModuleConfig.controls.addcurrentevent) {
      requestWindowAction({
        ...ModuleConfig.controls.addcurrentevent,
        params: {
          cardid,
          carddate,
          eventType
        }
      })
    }
  }

  return {
    dataLoading,
    dataLoaded,
    dataError,
    eventsList,
    getData,
    filters,
    selectedFilters,
    hasFiltersDefined,
    hasFiltersSet,
    moduleConfig,
    moduleMessages,
    recordEvent,
    calendarDates,
    otherCalendars,
    calendarView
  }
})

export const useCalendarStore = () => {
  const store = useDataStore()
  const refs = storeToRefs(store)

  return { ...store, ...refs }
}
