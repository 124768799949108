import type { ConfigDefinition } from './types'
//import { merge } from 'lodash'

export const DefaultConfig: ConfigDefinition = {
  urls: {
    getData: null,
    getEventCardInfo: null
  },
  messages: {
    panelTitle: 'Timetable calendar',
    loading: 'Loading...',
    programFilterLabel: 'Filter by program',
    programFilterPlaceholder: 'Select program',
    subjectFilterLabel: 'Filter by subject',
    subjectFilterPlaceholder: 'Select subject',
    teacherFilterLabel: 'Filter by teachers',
    teacherFilterPlaceholder: 'Select teacher',
    classFilterLabel: 'Filter by classes',
    classFilterPlaceholder: 'Select class',
    classroomFilterLabel: 'Filter by classrooms',
    classroomFilterPlaceholder: 'Select classroom',
    timeLabel: 'Time',
    classroomLabel: 'Classroom',
    teacherLabel: 'Teacher(s)',
    classesLabel: 'Classes',
    eventsListLabel: 'Events list',
	hoursLabel: 'hour(s)',
    closeLabel: 'Close',
    noFiltersTitle: 'No filters set',
    noFiltersDescription: 'Please select at least one filter to show the calendar',
    recordLabel: 'Record',
    recordOptionsDescription: 'Select event type to recrod for this single event',
    viewDetailsLabel: 'View details'
  },
  data: {
    filters: {},
    list: [],
    otherCalendars: {
      holidaysCalendar: {
        id: 'holidaysCalendar',
        name: 'Holidays',
        color: '#f5e3da',
        display: 'background',
        events: []
      },
      academicCalendar: {
        id: 'academicCalendar',
        name: 'Academic calendar',
        color: '#F6ECF5',
        display: 'background',
        events: []
      }
    }
  },
  config: {
    views: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    defaultview: 'dayGridMonth',
    userScope: 'none',
    stateScope: 'none',
    showHeader: true,
    loadOnlyFiltered: false,
    firstDay: 1,
    weekends: true,
    weekNumbers: false
  },
  controls: {}
}

//const InstanceConfig = window.TimetableCalendarConfig || {}

//export const ModuleConfig = merge(DefaultConfig, InstanceConfig)
