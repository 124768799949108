<script setup lang="ts">
import { EwPanel } from '@arkit/ew-vue-components'
import type { CalendarOptions, DatesSetArg } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { useCalendarStore } from '@/data-store'
import FiltersBlock from './FiltersBlock.vue'
import EventBlockView from './EventBlockView.vue'
import EventListView from './EventListView.vue'
import EventbackgroundView from './EventbackgroundView.vue'
import type { CalendarView } from '@/types'

const {
  dataLoading,
  eventsList,
  getData,
  hasFiltersSet,
  moduleConfig,
  moduleMessages,
  hasFiltersDefined,
  calendarDates,
  otherCalendars,
  calendarView
} = useCalendarStore()

const calendarInstance = ref()

const calendarOptions = ref<CalendarOptions>({
  plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
  initialView: calendarView.value ?? 'dayGridMonth',
  firstDay: moduleConfig.value.firstDay ?? 1,
  weekends: moduleConfig.value.weekends ?? true,
  weekNumbers: moduleConfig.value.weekNumbers ?? true,
  headerToolbar: moduleConfig.value.showHeader
    ? {
        left: 'prev,next today',
        center: 'title',
        right: moduleConfig.value.views ?? 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      }
    : false,
  aspectRatio: 1.8,
  //eventLimit: true,
  // * Relevant for time grid view
  height: 800,
  expandRows: true,
  nowIndicator: true,
  slotMinTime: '07:00:00',
  slotEventOverlap: false,
  //eventMinHeight: 64,
  //eventShortHeight: 50,
  //eventMaxStack: 0,
  slotDuration: '00:30:00',
  slotLabelInterval: '01:00:00'
})

const calendarConfig = computed(() => ({
  ...calendarOptions.value,
  eventSources: [
    { id: 'timetable', events: eventsList.value, display: 'block' },
    ...Object.values(otherCalendars.value)
  ],

  datesSet: (arg: DatesSetArg) => {
    //console.log(arg)
    calendarDates.value = { from: returnDateAsParam(arg.start), to: returnDateAsParam(arg.end) }
    calendarView.value = arg.view.type as CalendarView
  }
}))

function returnDateAsParam(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

onMounted(() => {
  if (calendarInstance.value) {
    console.log(calendarInstance.value.getApi())
  }
})
</script>

<template>
  <EwPanel
    :title="moduleMessages.panelTitle"
    :loading="dataLoading"
    showReload
    @reload="getData"
    compactContent
    icon="tabler:calendar"
  >
    <FiltersBlock v-if="hasFiltersDefined" class="mb-4 p-3 border-b bg-gray-50" />
    <div class="p-3">
      <FullCalendar
        v-if="hasFiltersSet || !moduleConfig.loadOnlyFiltered"
        ref="calendarInstance"
        :options="calendarConfig"
      >
        <template v-slot:eventContent="instance">
          <template v-if="instance.event.source.id === 'timetable'">
            <EventListView v-if="instance.view.type.startsWith('list')" :instance="instance" />
            <EventBlockView v-else :instance="instance" />
          </template>
          <EventbackgroundView v-else :instance="instance" />
        </template>
      </FullCalendar>
      <div
        v-if="moduleConfig.loadOnlyFiltered && !hasFiltersSet"
        class="h-50 text-center flex items-center"
      >
        <div class="w-1/2 mx-auto">
          <div class="text-2xl font-bold text-gray-400">{{ moduleMessages.noFiltersTitle }}</div>
          <div class="text-gray-500">{{ moduleMessages.noFiltersDescription }}</div>
        </div>
      </div>
    </div>
  </EwPanel>
</template>
