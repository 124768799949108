<script setup lang="ts">
import { EwPopover } from '@arkit/ew-vue-components'
import EventPopover from './EventPopover.vue'
import type { EventContentArg } from '@fullcalendar/core'
const props = defineProps({
  instance: { type: Object as PropType<EventContentArg>, required: true }
})
const event = computed(() => props.instance.event)
</script>
<template>
  <EwPopover trigger="click" placement="top-start" persist="content">
    <div class="gap-1 flex flex-col md:flex-row justify-between cursor-pointer">
      <div class="truncate font-medium md:w-1/2">{{ event.title }}</div>
      <div class="flex flex-col sm:flex-row justify-between md:gap-3 md:flex-1">
        <span
          v-if="event.extendedProps.classroom"
          class="flex items-center gap-1 whitespace-nowrap"
        >
          <EwIcon icon="tabler:building" />
          <span v-for="classroom in event.extendedProps.classroom" :key="classroom.id">
            {{ classroom.short }}
          </span>
        </span>
        <span v-if="event.extendedProps.teacherids" class="flex items-center truncate">
          <EwIcon icon="tabler:user" class="mr-1"/>
		  <span v-if="!Object.keys(event.extendedProps.teacherids).length" class="text-gray-400 text-xs">Not set</span>
          <template
            v-for="(teacher, teacherKey, teacherInd) in event.extendedProps.teacherids"
            :key="teacher.id"
          >
            <span v-if="teacherInd !== 0" class="inline-block pr-1">, </span>
            <span>
              {{ `${teacher.firstname} ${teacher.lastname}` }}
            </span>
          </template>
        </span>
      </div>
    </div>
    <template #content="{ close }">
      <EventPopover :instance="props.instance" :close="close" />
    </template>
  </EwPopover>
</template>
