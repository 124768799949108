import '@unocss/reset/tailwind.css'
import '@arkit/ew-vue-components/dist/style.css'
import 'virtual:uno.css'
import '@/assets/main.css'

import type { App } from 'vue'
import * as components from './components'
import { createPinia } from 'pinia'

import { easyWebComponents } from '@arkit/ew-vue-components'

function install(app: App) {
  const pinia = createPinia()
  app.use(pinia)
  app.use(easyWebComponents, { registerComponents: false })
  for (const key in components) {
    // @ts-expect-error
    app.component(key, components[key])
  }
}

export default { install }

export * from './components'
export * as components from './components'
