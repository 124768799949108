<script setup lang="ts">
import { EwDropdown } from '@arkit/ew-vue-components'
import type { EventContentArg } from '@fullcalendar/core'
import type { EventItem, PotentialEventTypes, CardInfo } from '@/types'
import { useCalendarStore } from '@/data-store'

const props = defineProps({
  instance: { type: Object as PropType<EventContentArg>, required: true },
  close: { type: Function, required: true }
})

const { recordEvent, moduleMessages } = useCalendarStore()

const event = computed(() => props.instance.event)
const eventData = computed(() => event.value.extendedProps as EventItem)

function getEventTypeOptions(
  potentialeventtypes: PotentialEventTypes,
  cardid: CardInfo['cardid'],
  carddate: CardInfo['carddate']
) {
  const options = []
  for (const key in potentialeventtypes) {
    if (Object.prototype.hasOwnProperty.call(potentialeventtypes, key)) {
      const eventKey = key as keyof typeof potentialeventtypes
      const element = potentialeventtypes[eventKey]
      options.push({
        label: element ?? '',
        action: () => handleAddAction({ cardid, carddate, eventType: eventKey })
      })
    }
  }
  return options
}

function handleAddAction(args: {
  cardid: CardInfo['cardid']
  carddate: CardInfo['carddate']
  eventType: keyof PotentialEventTypes
}) {
  const { cardid, carddate, eventType } = args
  console.log('handleAddAction', cardid, carddate, eventType)
  //emit('recrodevent', { cardid, carddate, eventType })
  recordEvent({ cardid, carddate, eventType })
}
</script>
<template>
  <div>
    <div class="font-bold border-b pb-2 mb-3 flex items-center gap-2">
      <div
        class="w-4 h-4 rounded-full"
        :style="{ backgroundColor: instance.backgroundColor }"
      ></div>
      {{ event.title }}
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex">
        <span class="w-30"><EwIcon icon="tabler:clock" /> {{moduleMessages.timeLabel}}:</span>
        <div class="font-medium">{{ eventData.starttime }} - {{ eventData.endtime }}</div>
      </div>
      <div class="flex">
        <span class="w-30"><EwIcon icon="tabler:building" /> {{moduleMessages.classroomLabel}}:</span>
        <div class="font-medium">
          <span v-for="classroom in eventData.classroom" :key="classroom.id">
            {{ classroom.short }}
          </span>
        </div>
      </div>
      <div class="flex">
        <span class="w-30"><EwIcon icon="tabler:user" /> {{moduleMessages.teacherLabel}}:</span>
        <div class="font-medium">
          <template
            v-for="(teacher, teacherKey, teacherInd) in eventData.teacherids"
            :key="teacher.id"
          >
            <span v-if="teacherInd !== 0">, </span>
            <span>
              {{ `${teacher.firstname} ${teacher.lastname}` }}
            </span>
          </template>
        </div>
      </div>
      <div class="flex">
        <span class="w-30 whitespace-nowrap"><EwIcon icon="tabler:users" /> {{moduleMessages.classesLabel}}:</span>
        <div class="font-medium">
          <template v-for="classItem in eventData.classids" :key="classItem.id">
            <div class="whitespace-nowrap flex gap-1 items-center">
              {{ classItem.mappinginfo.rolename }}
              <span class="text-xs text-gray-500 font-normal">({{ classItem.name }})</span>
            </div>
          </template>
        </div>
      </div>
      <div>
        <div class="font-medium pb-1 border-b flex justify-between gap-3 items-center">
          <span>{{moduleMessages.eventsListLabel}}</span>
          <span class="text-gray-500 text-xs font-normal">{{ eventData.nrofevents }} {{moduleMessages.hoursLabel}}</span>
        </div>
        <div class="flex flex-col gap-2 divide-y">
          <div
            v-for="(event, eventKey, eventInd) in eventData.events"
            :key="event.cardid"
            class="flex items-center gap-3 pt-2 justify-between"
          >
            <span class="flex gap-2">
              <div class="w-5 text-right">{{ eventInd + 1 }}.</div>
              {{ event.starttime }} - {{ event.endtime }}
            </span>
            <span v-if="event.flagtoregister && !event.isregistered">
              <EwDropdown
                class="text-primary cursor-pointer"
                title="Record attendance"
                placement="bottom-end"
                :options="
                  getEventTypeOptions(event.potentialeventtypes, event.cardid, event.carddate)
                "
              >
                {{moduleMessages.recordLabel}}
                <template #menuheader>
                  <div class="p-4 bg-primary text-white w-60">
                    {{moduleMessages.recordOptionsDescription}}
                  </div>
                </template>
              </EwDropdown>
            </span>
            <span v-if="event.isregistered" class="whitespace-nowrap text-primary cursor-pointer">
              <EwIcon icon="tabler:external-link" class="mr-1" />{{moduleMessages.viewDetailsLabel}}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="border-t mt-3 pt-2 flex justify-between">
      <a class="text-primary cursor-pointer" @click="close()">{{moduleMessages.closeLabel}}</a>
      <div>ssss</div>
      <!-- <EwDropdown
        class="text-primary cursor-pointer"
        title="Record attendance"
        placement="bottom-end"
        :options="getEventTypeOptions(event.potentialeventtypes)"
      >
        Record
        <template #menuheader>
          <div class="p-4 bg-primary text-white w-60">
            Select event type to recrod for this single event
          </div>
        </template>
      </EwDropdown> -->
    </div>
  </div>
</template>
