type Params = { [key: string]: any } | Array<{ [key: string]: any }>
export type WindowActionConfig = {
  url: string
  title?: string
  width?: string | number
  height?: string | number
  icon?: string
  modal?: boolean
  params?: Params
  hash?: Params
  confirmmessage?: string
}

export const WindowActionConfigDefault: Partial<WindowActionConfig> = {
  title: 'Action window',
  width: 600,
  height: 800,
  modal: false
}

export type AjaxActionConfig = {
  url: string
  confirmmessage?: string
  loadingmessage?: string
  successgmessage?: string
  errorgmessage?: string
  callconfig?: {
    method?: 'POST' | 'GET' | 'PUT' | 'DELETE'
    dataType?: 'json'
  }
}

export const AjaxActionConfigDefault: Partial<AjaxActionConfig> = {
  loadingmessage: 'Updating data...',
  successgmessage: 'Operation was successfull',
  errorgmessage: 'Operation failed',
  callconfig: {
    method: 'POST',
    dataType: 'json'
  }
}

declare global {
  interface Window {
    EWFrontendEdit?: {
      requestWindowAction: (config: WindowActionConfig, callbackFn?: (event: any) => void) => void
      requestAjaxAction: (config: AjaxActionConfig, callbackFn?: (event: any) => void) => void
    }
  }
}

export const requestWindowAction = (
  config: WindowActionConfig,
  callbackFn?: (event: any) => void
) => {
  if (window.EWFrontendEdit) {
    window.EWFrontendEdit.requestWindowAction(config, callbackFn)
  }
}

export const requestAjaxAction = (config: AjaxActionConfig, callbackFn?: (event: any) => void) => {
  if (window.EWFrontendEdit) {
    window.EWFrontendEdit.requestAjaxAction(config, callbackFn)
  }
}