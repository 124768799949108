<script setup lang="ts">
import type { EventContentArg } from '@fullcalendar/core'
const props = defineProps({
  instance: { type: Object as PropType<EventContentArg>, required: true }
})
const event = computed(() => props.instance.event)
</script>
<template>
  <div class="inline-block !text-xs italic opacity-50 p-1.5 pr-3">{{ event.title }}</div>
</template>
