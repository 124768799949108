<script setup lang="ts">
import { useCalendarStore } from '@/data-store'
import { EwSelect } from '@arkit/ew-vue-components'

const { filters, moduleMessages } = useCalendarStore()
</script>
<template>
  <div class="grid grid-cols-6 gap-3">
    <EwSelect
      v-if="filters.program"
	  v-model="filters.program.selectedObject[0]"
      :label="moduleMessages.programFilterLabel"
      :options="filters.program.values"
      optionLabel="name"
      optionGroupLabel="name"
      optionGroupChildren="childs"
      filter
	  :placeholder="moduleMessages.programFilterPlaceholder"
    />
    <EwSelect
      v-if="filters.subjects"
      v-model="filters.subjects.selectedObject[0]"
      :label="moduleMessages.subjectFilterLabel"
      :options="filters.subjects.values"
      optionLabel="name"
      filter
	  :placeholder="moduleMessages.subjectFilterPlaceholder"
	  class="col-span-2"
	  :key="`subject-filter-${filters.subjects.selectedObject[0]?.id}`"
    >
      <template #value="{ value }">
        <div v-if="value" class="flex items-center">
          <div class="min-w-4 h-4 mr-2 rounded-full" :style="{ backgroundColor: value.color }" />
          <span><span v-if="value.short">{{ value.short }} - </span>{{ value.name }}</span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex items-center">
          <div class="w-4 h-4 mr-2 rounded-full" :style="{ backgroundColor: option.color }" />
          <span><span v-if="option.short">{{ option.short }} - </span>{{ option.name }}</span>
        </div>
      </template>
    </EwSelect>
    <EwSelect
      v-if="filters.teachers"
      v-model="filters.teachers.selectedObject[0]"
      :label="moduleMessages.teacherFilterLabel"
      :options="filters.teachers.values"
      optionLabel="name"
      filter
	  :placeholder="moduleMessages.teacherFilterPlaceholder"
    />
    <EwSelect
      v-if="filters.classes"
      v-model="filters.classes.selectedObject[0]"
      :label="moduleMessages.classFilterLabel"
      :options="filters.classes.values"
      optionLabel="name"
      filter
	  :placeholder="moduleMessages.classFilterPlaceholder"
    />
    <EwSelect
      v-if="filters.classrooms"
      v-model="filters.classrooms.selectedObject[0]"
      :label="moduleMessages.classroomFilterLabel"
      :options="filters.classrooms.values"
      optionLabel="name"
      filter
	  :placeholder="moduleMessages.classroomFilterPlaceholder"
    />
  </div>
</template>
